header {
  @include MediaMaxandMin(576, 1199) {
    padding-top: 100px;
  }
}

.navbar {
  position: absolute;
  padding-top: 1rem;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  height: auto;
  background-color: transparent;

  &.tabHeader {
    background-color: #181D5D;
  }

  @include Media(1199) {
    background: nth($color, 2);

    .navbar-collapse {
      padding-bottom: 30px;
    }
  }

  .navbar-brand {
    display: block;
    width: 100px;

    @include Media(575) {
      width: 60px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .navbar-toggler {
    outline: none;
    box-shadow: none;
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .headerLinks {
    @include maxDriveRegular(1.2, 1);
    color: nth($color, 1) !important;
    padding: 10px 15px !important;

    @include Media(1366) {
      font-size: 1rem;
    }

    &:hover {
      color: nth($color, 3) !important;
    }
  }

  .active {
    color: nth($color, 3) !important;
  }

  .socialLinks {
    position: relative;

    a {
      color: nth($color, 3);
      margin: 0 4px;
    }

    .btnLi {
      position: relative;

      @include Media (1199) {
        width: 100%;
        margin-top: 20px;
      }

      a {
        margin-left: 10px;
        position: relative;
        padding: 10px 20px;
        background: nth($color, 3);
        border: 1px solid nth($color, 3);
        @include maxDriveRegular(0.8, 1);
        clip-path: polygon(92% 0, 100% 36%, 100% 100%, 0 100%, 0 0);
        @include BorRadius(5px);
        @include Tran(0.4s, ease-in-out);

        &:hover {
          color: nth($color, 3);
          background: transparent;
        }
      }
    }
  }
}