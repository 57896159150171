.roadmapSec {
  position: relative;
  padding: 150px 0px;
  z-index: 1;

  @include Media(767) {
    padding: 100px 10px;

    .container {
      max-width: 100%;
      background: rgba(0, 0, 0, .4)
    }
  }

  .roadmapWrapper {

    h1 {
      @include sectionTitle(5, right);
      text-align: center;
      margin: 30px 0 100px;

      @include Media(767) {
        font-size: 4rem;
      }

      @include Media(480) {
        font-size: 3rem;
      }
    }

    h6 {
      @include pinmoldRegular(4, 3);
      margin-bottom: 15px;
      text-shadow: 0px 0px 20px #50006C, 0px 0px 20px #F1BE3F, 0px 0px 20px #870000, 0px 0px 5px #510000, 0px 0px 6px #FFB600, 0px 0px 10px #662B00;

      @include Media(480) {
        font-size: 3rem;
      }
    }

    .contentSec {
      z-index: 99;
      padding-left: 2rem;
      margin-bottom: 50px;

      li {
        @include gilroyRegular(1.2, 1);
        line-height: 1.8rem;

        @include Media(480) {
          font-size: 1rem;
        }

        img {
          display: inline-block;
          width: 20px;
          height: auto;
          margin-right: 5px;
        }
      }
    }

    .posImg {
      z-index: -1;
      position: absolute;
      width: 300px;
      height: auto;
      right: 50px;
      bottom: 50px;

      @include Media(575) {
        width: 200px;
        bottom: 100px;
      }
    }
  }
}