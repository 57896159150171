.privacyPolicyPage {
  padding: 100px 0;
  min-height: 100vh;

  @include Media(575) {
    padding: 50px 0;
  }

  h2 {
    @include sectionTitle(5, left);
    margin-bottom: 2.5rem;

    @include Media(767) {
      font-size: 4rem;
    }

    @include Media(480) {
      font-size: 3rem;
    }
  }

  h5 {
    @include gilroyBold(2, 1);

    @include Media(575) {
      font-size: 1.5rem;
    }

    margin-bottom: 1rem;
  }

  p {
    @include gilroyRegular(1.2, 1);
    margin-bottom: 1rem;

    @include Media(575) {
      font-size: 1rem;
    }
  }

  ul {
    list-style: inside;
    @include gilroyRegular(1.2, 1);
    margin-bottom: 1.5rem;

    @include Media(575) {
      font-size: 1rem;
    }

    li {
      margin-bottom: .8rem;
    }
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
    @include gilroyRegular(1.2, 1);
    margin-bottom: 1.5rem;

    @include Media(575) {
      font-size: 1rem;
    }

    li {
      margin-bottom: .8rem;
    }
  }

  a,
  a:hover {
    color: nth($color, 1);
  }
}