@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'gilroy-lightuploaded_file';
  src: url('./fonts/gilroy-light-webfont.eot');
  src: url('./fonts/gilroy-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gilroy-light-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-regularuploaded_file';
  src: url('./fonts/gilroy-regular-webfont.eot');
  src: url('./fonts/gilroy-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gilroy-regular-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-semibolduploaded_file';
  src: url('./fonts/gilroy-semibold-webfont.eot');
  src: url('./fonts/gilroy-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gilroy-semibold-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-mediumuploaded_file';
  src: url('./fonts/gilroy-medium-webfont.eot');
  src: url('./fonts/gilroy-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gilroy-medium-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-bolduploaded_file';
  src: url('./fonts/gilroy-bold-webfont.eot');
  src: url('./fonts/gilroy-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gilroy-bold-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'maxdriveregular';
  src: url('./fonts/maxdrive-webfont.eot');
  src: url('./fonts/maxdrive-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/maxdrive-webfont.woff2') format('woff2'),
    url('./fonts/maxdrive-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pinmold_demoregular';
  src: url('./fonts/pinmolddemo-jeaxv-webfont.eot');
  src: url('./fonts/pinmolddemo-jeaxv-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/pinmolddemo-jeaxv-webfont.woff2') format('woff2'),
    url('./fonts/pinmolddemo-jeaxv-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* =============================
        HTML & BODY
============================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 14px;
  overflow-x: hidden !important;
}

body {
  width: 100vw !important;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background: nth($color, 2);
  font-family: 'gilroy-regularuploaded_file', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.DBlock,
header,
section,
footer {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.DFlex {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: normal;
  text-transform: none;
  line-height: normal;
  margin-bottom: 0;
  text-align: justify;
}

label {
  margin-bottom: 0;
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.FocusNone,
a:focus,
button:focus,
textarea:focus,
input:focus,
select:focus {
  box-shadow: none;
  outline: none !important;
}

.TransAni {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

.imgCover {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}

button {
  cursor: pointer;
}

//Scroll Bar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;

  @include Media(575) {
    width: 3px;
    height: 3px;
  }
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: #970C5F;
}

/*spinner */
.loading {
  position: relative;
  @include Flex(center, center);
  min-height: 100vh;
  background: nth($color, 6 );
  .spinner {
      width: 8vmax;
      height: 8vmax;
      border-right: 4px solid nth($color, 7 );
      border-radius: 100%;
      animation: spinRight 800ms linear infinite;
      
      &:before, &:after {
        content: '';
        width: 6vmax;
        height: 6vmax;
        display: block;
        position: absolute;
        top: calc(50% - 3vmax);
        left: calc(50% - 3vmax);
        border-left: 3px solid nth($color, 7 );
        border-radius: 100%;
        animation: spinLeft 800ms linear infinite;
      }
      
      &:after {
        width: 4vmax;
        height: 4vmax;
        top: calc(50% - 2vmax);
        left: calc(50% - 2vmax);
        border: 0;
        border-right: 2px solid nth($color, 7 );
        animation: none;
      }
    }
    
    @keyframes spinLeft {
      from {transform:rotate(0deg);}
      to {transform:rotate(720deg);}
    }
    
    @keyframes spinRight {
      from {transform:rotate(360deg);}
      to {transform:rotate(0deg);}
    }
}