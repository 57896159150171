.homeSec {
  position: relative;
  z-index: 1;

  @include Media(575) {
    padding-top: 85px !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 1;
  }

  .homeBgImg {
    width: 100%;
    height: auto;

    @include Media(575) {
      min-height: calc(100vh - 82px);
      object-fit: cover;
    }
  }

  .homeContent {
    position: absolute;
    bottom: 20%;
    left: 10%;
    width: 100%;
    height: auto;
    z-index: 99;

    @include Media(575) {
      bottom: 20%;
      left: 5%;
      width: 98%;
    }

    h1 {
      @include pinmoldRegular(13, 3);
      margin-bottom: 50px;
      color: rgba(255, 182, 0, 0.5);
      -webkit-text-stroke: 5px #ffb600;

      @include Media(991) {
        font-size: 8rem;
      }

      @include Media(767) {
        font-size: 5rem;
      }

      @include Media(575) {
        font-size: 3.5rem;
        margin-bottom: 30px;
      }
    }

    .homeBtns {
  position: relative;

  a {
    margin-right: 10px;
    position: relative;
    padding: 10px 50px;
    background: nth($color, 3);
    border: 1px solid nth($color, 3);
    @include maxDriveRegular(1, 1);
    clip-path: polygon(93% 0, 100% 36%, 100% 100%, 0 100%, 0 0);
    @include BorRadius(5px);
    @include Tran(0.4s, ease-in-out);

    &.whitePaperBtn {
      border-color: rgba(255, 255, 255, .3);
      background: rgba(0, 0, 0, .2);
      cursor: pointer;
    }

    &:hover {
      color: nth($color, 3);
      background: transparent;
    }

    @include Media(575) {
      padding: 5px 25px;
    }
  }

  a:nth-child(2) {
    padding: 10px 30px;
    background: nth($color, 2);
    border-color: #a23001;

    &:hover {
      background: transparent;
      color: #a23001;
    }

    @include Media(575) {
      padding: 5px 25px;
      margin: 10px 0;
    }
  }
}
  }
}