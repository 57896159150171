.teamSec {
  position: relative;
  padding: 150px 0px;

  .teamWrapper {
    h1 {
      @include sectionTitle(5, right);
      text-align: center;

      @include Media(767) {
        font-size: 4rem;
      }

      @include Media(480) {
        font-size: 3rem;
      }
    }

    .teamDetails {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .imgSection {
        position: relative;

        .insta {
          position: absolute;
          max-width: 30%;
          bottom: 3%;
          right: 10%;
        }
      }

      .userName {
        @include maxDriveRegular(1.2, 1);

        @include Media(767) {
          font-size: 1rem;
        }
      }

      .designation {
        @include poppins(1.2, 5);
        text-align: center;
      }
    }
  }
}

/*development team section*/
.developmentSec {
  position: relative;
  padding: 100px 0px;

  .devWrapper {
    h1 {
      @include sectionTitle(5, right);
      text-align: center;

      @include Media(767) {
        font-size: 4rem;
      }

      @include Media(480) {
        font-size: 3rem;
      }
    }

    .devDetails {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .userName {
        @include maxDriveRegular(1.2, 1);

        @include Media(767) {
          font-size: 1rem;
        }
      }

      .designation {
        @include poppins(1.2, 5);
        text-align: center;
      }
    }
  }
}

/*mission team section*/
.missionSec {
  position: relative;
  padding: 100px 0px;
  background-color: nth($color, 2);

  .missionWrapper {
    h1 {
      @include sectionTitle(5, right);
      text-align: center;

      @include Media(767) {
        font-size: 4rem;
      }

      @include Media(480) {
        font-size: 3rem;
      }
    }

    .missionContentSec {
      @include Media(767) {
        flex-direction: column;
        flex-flow: column-reverse;
      }

      .missionDetails {
        position: relative;

        img {
          max-width: 200px;
          margin: 0 auto;
        }

        @include Media(480) {
          padding: 0px 20px;
        }

        .contentSec {
          padding-left: 2rem;
          padding-bottom: 2rem;

          @include Media(575) {
            padding-left: 1rem;
            padding-bottom: 1rem;
          }

          li {
            @include gilroyMedium(1.5, 1);
            line-height: 1.9rem;

            @include Media(575) {
              font-size: 1rem;
              line-height: auto;
            }

            .freeWay {
              color: nth($color, 3);
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: .1em;
            }

            .link {
              color: nth($color, 7);
            }

            @include Media(991) {
              @include gilroyMedium(1.2, 1);
            }

            @include Media(576) {
              @include gilroyMedium(1, 1);
            }
          }

          &.leftContent {
            border-left: 2px solid nth($color, 7);
            border-bottom: 2px solid nth($color, 7);
          }
        }

        .linkSecWrapper {
          border-left: 2px solid #4bd9c7;
          border-bottom: 2px solid #4bd9c7;
          margin-top: 2rem;

          .contentSec {
            // border-right: 2px solid nth($color, 7);
            // padding-right: 2rem;
            // margin-bottom: 2rem;

            @include Media(991) {
              margin-bottom: 0;
            }

            @include Media(575) {
              padding-left: 1rem;
              padding-right: 1rem;
              padding-bottom: 1rem;
            }
          }

          .linkSec {
            @include Media(991) {
              padding: 0 2rem 2rem !important;
            }

            .webLink {
              @include gilroyMedium(1.1, 10);
              letter-spacing: 0.06em;
              border-bottom: 2px solid nth($color, 7);
              padding-left: 5px;
            }
          }
        }

        .img1Sec {
          margin-top: 7rem;

          @include Media(767) {
            margin-top: 0;
          }
        }

        .img4Sec {
          margin-top: 10rem;

          @include Media(767) {
            margin-top: 0;
          }
        }
      }
    }
  }
}