.whitePaperSec {
  position: relative;

  @include Media(575) {
    padding-top: 85px !important;
  }

  .whitePaperBgImg {
    width: 100%;
    height: auto;
    position: relative;

    @include Media(575) {
      object-fit: contain;
    }
  }

  .whitePaperDiv {
    padding: 300px 0;

    @include Media(767) {
      padding: 200px 0;
    }

    h1 {
      @include pinmoldRegular(9, 3);
      color: rgba(255, 182, 0, 0.5);
      -webkit-text-stroke: 5px #ffb600;

      @include Media(991) {
        font-size: 5rem;
      }

      @include Media(767) {
        text-align: center;
        font-size: 5rem;
      }

      @include Media(575) {
        font-size: 3.5rem;
      }
    }

    h2 {
      @include pinmoldRegular(8, 3);
      margin-bottom: 50px;
      color: nth($color, 1);

      @include Media(991) {
        font-size: 5rem;
      }

      @include Media(767) {
        text-align: center;
        font-size: 5rem;
      }

      @include Media(575) {
        font-size: 3.5rem;
      }
    }

    p {
      font-size: 1.8rem;
      color: nth($color, 1);
      margin-bottom: 30px;

      @include Media(991) {
        font-size: 1.5rem;
      }

      @include Media(767) {
        text-align: center;
      }

      @include Media(575) {
        font-size: 1.2rem;
      }
    }

    .whitePaperImg {
      padding: 30px;

      @include Media(991) {
        padding: 10px;
      }

      @include Media(767) {
        max-width: 250px;
        margin: 0 auto 30px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .whitePapertns {
      position: relative;

      a {
        position: relative;
        padding: 10px 50px;
        background: nth($color, 3);
        border: 1px solid nth($color, 3);
        @include maxDriveRegular(1, 1);
        @include BorRadius(5px);
        @include Tran(0.4s, ease-in-out);
        cursor: pointer;

        &:hover {
          color: nth($color, 3);
          background: transparent;
        }

        @include Media(575) {
          padding: 5px 25px;
        }
      }
    }
  }
}