$color: #ffffff, #000000, #ffb600, #38c558, #999999, #121525, #4bd9c7, #52ff00, #699BF7,#0ED1FB, #4B4B4B;

@mixin Flex($var1, $var2) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: $var1;
  align-items: $var2;
}

@mixin FlexCol($var1, $var2) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: $var1;
  align-items: $var2;
}

@mixin ImgBor($var1, $var2) {
  width: $var1;
  height: $var1;
  overflow: hidden;
  margin: 0 auto 15px;
  border: 4px solid nth($color, $var2);
  @include BorRadius(50%);

  img {
    @include Img(cover);
  }
}

@mixin Padding($var1, $var2) {
  padding: $var1 + rem $var2 + rem;
}

@mixin BG($var) {
  background: url('../images/#{$var}') no-repeat center/cover;
}

@mixin Media($var) {
  @media only screen and (max-width: $var + px) {
    @content;
  }
}

@mixin MinMedia($var) {
  @media only screen and (min-width: $var + px) {
    @content;
  }
}

@mixin MediaMaxandMin($var1, $var2) {
  @media (min-width: $var1 + px) and (max-width: $var2 + px) {
    @content;
  }
}

@mixin Absolute($var1, $var2, $var3, $var4, $var5, $var6, $var7) {
  content: $var1;
  position: absolute;
  top: $var2;
  bottom: $var3;
  left: $var4;
  right: $var5;
  width: $var6;
  height: $var7;
}

@mixin Tran($var1, $var2) {
  transition: all $var1 $var2;
  -webkit-transition: all $var1 $var2;
  -moz-transition: all $var1 $var2;
  -ms-transition: all $var1 $var2;
  -o-transition: all $var1 $var2;
}

@mixin TransForm($var) {
  transform: $var;
  -webkit-transform: $var;
  -moz-transform: $var;
  -ms-transform: $var;
  -o-transform: $var;
}

@mixin BorRadius($var) {
  border-radius: $var;
  -webkit-border-radius: $var;
  -moz-border-radius: $var;
  -ms-border-radius: $var;
  -o-border-radius: $var;
}

@mixin Img($var) {
  width: 100%;
  height: 100%;
  object-fit: $var;
}

@mixin Font($var1, $var2, $var3) {
  font-size: $var1 + rem;
  font-weight: $var2;

  @if $var3>0 {
    color: nth($color, $var3);
  }
}

@mixin gilroyLight($var1, $var2) {
  font-family: 'gilroy-lightuploaded_file';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin gilroyRegular($var1, $var2) {
  font-family: 'gilroy-regularuploaded_file';
  font-size: $var1 + rem;
  color: nth($color, $var2);
  letter-spacing: 0.06em;
}

@mixin gilroySemibold($var1, $var2) {
  font-family: 'gilroy-semibolduploaded_file';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin gilroyMedium($var1, $var2) {
  font-family: 'gilroy-mediumuploaded_file';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin gilroyBold($var1, $var2) {
  font-family: 'gilroy-bolduploaded_file';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin maxDriveRegular($var1, $var2) {
  font-family: 'maxdriveregular';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin pinmoldRegular($var1, $var2) {
  font-family: 'pinmold_demoregular';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}
@mixin Merriweather($var1, $var2) {
  font-family: 'Merriweather';
  font-size: $var1 + rem;
  color: nth($color, $var2);
}
@mixin poppins($var1, $var2) {
  font-family: 'Poppins', sans-serif;
  font-size: $var1 + rem;
  color: nth($color, $var2);
}

@mixin sectionTitle($var1, $var2) {
  font-family: 'pinmold_demoregular';
  font-size: $var1 + rem;
  padding-bottom: 1rem;
  text-align: $var2;
  letter-spacing: 0.06em;
  color: rgba(255, 182, 0, 0.5);
  -webkit-text-stroke: 5px #ffb600;
  text-shadow: 0px 0px 18px #50006c, 0px 0px 24px #f1be3f, 0px 0px 18px #870000,
    0px 0px 6px #510000, 0px 0px 8px #ffb600, 0px 0px 12px #662b00;
}

@mixin exploreTitle($var1, $var2) {
  font-family: 'maxdriveregular';
  font-size: $var1 + rem;
  padding-bottom: 1rem;
  text-align: center;
  letter-spacing: 0.06em;
  color: rgba(80, 59, 6, 0.445);
  -webkit-text-stroke: 3px #ffb600;
  text-shadow: 0px 0px 18px #50006c, 0px 0px 24px #f1be3f, 0px 0px 18px #870000,
    0px 0px 6px #510000, 0px 0px 8px #ffb600, 0px 0px 12px #662b00;
}