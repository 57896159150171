.explorePage {
  position: relative;
  padding: 98px 0px 0px;
  background-color: #181D5D;
  
  @include Media (1200) {
    padding: 0px;
  }

  /*scrollbarContent*/
  .scrollbarContent {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /*intro section*/
  .introSec {
    position: relative;
    background: url('../../public/assets/images/explore/introBg.jpg') center/cover no-repeat;
    padding-bottom: 100px;
    padding-top: 50px;

    .arrowImg {
      position: absolute;
      top: 70%;
      right: 2%;
      width: 8%;
      cursor: pointer;

      @include Media(767) {
        width: 12%;
      }
    }

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, left);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    li {
      @include poppins(1.6, 1);
      line-height: 46px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }
    }
  }

  /*kash section*/
  .kashSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/kashBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, right);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li {
        list-style: none;
        padding: 10px 0;
      }

      li:nth-child(3) {
        color: nth($color, 3);
      }
    }
  }

  /*da game Nft Section*/
  .daGameNftSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/nftBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, left);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    li {
      @include poppins(1.6, 1);
      line-height: 46px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }
    }
  }

  /*metaverse section*/
  .metaVerseSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/metaverseBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, right);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li:nth-child(1) {
        color: nth($color, 3);
      }

      li:nth-child(even) {
        padding: 20px 0;
      }

      li:nth-child(odd) {
        color: nth($color, 3);
      }

      .percentage {
        color: nth($color, 10);
      }
    }
  }

  /*service section*/
  .serviceSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/serviceBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, left);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      span {
        color: nth($color, 10);
      }
    }
  }

  /*functionality section*/
  .functionalitySec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/functionalityBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, right);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    .title {
      list-style-type: none;
      font-weight: 500;
      @include poppins(1.6, 3);
    }

    .desc {
      @include poppins(1.6, 1);
      font-weight: 500;
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      list-style-type: disc;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      ol {
        padding-left: 30px;
      }

      b {
        color: nth($color, 3)
      }

    }
  }

  /*syndicate nft section*/
  .syndicateNftSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/syndicateNftBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, left);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      list-style-type: disc;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li {
        list-style-type: none;
      }

      span {
        color: nth($color, 10);
      }
    }

    .characterType {
      li {
        list-style-type: disc !important;
        margin-left: 25px;
      }
    }
  }

  /*landNft section*/
  .landNftSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/landNftBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, right);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5),
      li:nth-child(6),
      li:nth-child(7),
      li:nth-child(8),
      li:nth-child(17),
      li:nth-child(9) {
        color: nth($color, 3);
        list-style-type: none;
      }

      span {
        color: nth($color, 3);
      }
    }
  }

  /*transport, moneyMoulingSec and luandering  nft section*/
  .transportNftSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/transportNftBg.jpg') center/cover no-repeat;

    &.luanderingNftSec {
      padding-top: 50px;
      background: url('../../public/assets/images/explore/launderingNftBg.jpg') center/cover no-repeat;

      ul.launderyList {
        li {
          color: white;
        }

        li:nth-child(5),
        li:nth-child(3) {
          color: nth($color, 3);
          list-style-type: none;
          padding: 10px 0;
        }

        ol {
          padding-left: 45px;

          li:nth-child(5),
          li:nth-child(3),
          li {
            color: white;
            padding: 0px;
            list-style-type: disc;
          }
        }
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    &.stakingSec {
      padding-top: 50px;
      background: url('../../public/assets/images/explore/stakingBg.jpg') center/cover no-repeat;

      .stakeList {

        li:nth-child(1),
        li:nth-child(4) {
          color: nth($color, 3);
        }
      }
    }

    &.moneyMulingSec {
      padding-top: 50px;
      background: url('../../public/assets/images/explore/syndicateNftBg.jpg') center/cover no-repeat;

      ul.moulingList {

        li:nth-child(5),
        li:nth-child(3) {
          color: nth($color, 1);
          list-style-type: disc;
        }

        li:nth-child(4) {
          list-style-type: none;
        }

        span {
          color: nth($color, 3)
        }
      }

      .stakeList {
        li {
          list-style-type: disc;
        }

        li:nth-child(1) {
          color: nth($color, 3);
          list-style-type: none;
        }
      }
    }

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, left);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li {
        color: nth($color, 1);
        list-style-type: none;
      }

      ol {
        padding-left: 30px;

        li {
          list-style-type: disc;
        }
      }

      .percentage {
        color: nth($color, 10);
      }
    }
  }

  /*distribution, marketPlaceExploreSec and special nft section*/
  .distributionSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/distributionNftBg.jpg') center/cover no-repeat;

    &.marketPlaceExplore {
      padding-top: 50px;
      background: url('../../public/assets/images/explore/marketplaceBg.jpg') center/cover no-repeat;
    }

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(4.5, right);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    .title {
      list-style-type: none;
      font-weight: 500;
      @include poppins(1.6, 3);
    }

    .desc {
      @include poppins(1.6, 1);
      font-weight: 500;
    }

    ul {
      @include poppins(1.6, 1);
      line-height: 40px;
      font-weight: 500;
      text-align: left;

      @include Media(767) {
        @include poppins(1.2, 1);
        line-height: 30px;
      }

      li.color {
        padding: 10px 0;
        color: nth($color, 3);
      }

      ol {
        padding-left: 30px;
        list-style: disc;
        margin-bottom: 10px;
      }

    }

    &.marketPlaceExplore {

      li:nth-child(1),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(10),
      li:nth-child(24) {
        list-style-type: none;
        color: nth($color, 3);
      }
    }
  }

  /*roadMap Explore section*/
  .roadMapExploreSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/roadmapBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, center);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    .desktopViewScreen {
      position: relative;

      @include Media(991) {
        display: none;
      }

      .contentSec {
        li {
          @include poppins(1, 1);
          line-height: 22px;
          font-weight: 500;
          list-style-type: disc;

          @include Media(767) {
            @include poppins(0.9, 1);
            line-height: 20px;
          }
        }

        &.contentPos1 {
          position: absolute;
          bottom: 14%;
          right: 12%;

          li:nth-child(4) {
            max-width: 300px;
          }
        }

        &.contentPos2 {
          position: absolute;
          bottom: 18%;
          left: 12%;

          li {
            max-width: 250px;
          }
        }

        &.contentPos3 {
          position: absolute;
          bottom: 52%;
          right: 12%;

          @include Media(1200) {
            right: 6%;
          }
        }

        &.contentPos5 {
          position: absolute;
          bottom: 50%;
          left: -2%;

          @include Media(1200) {
            bottom: 43%;
          }

          li {
            max-width: 230px;
          }
        }

        &.contentPos4 {
          position: absolute;
          bottom: 60%;
          left: 35%;

          li {
            max-width: 220px;
          }
        }
      }
    }

    .mobileViewScreen {
      display: none;

      @include Media(991) {
        display: block;
      }

      ol {
        @include poppins(3.1, 3);
        font-weight: 500;
        padding-right: 35px;
        letter-spacing: 0.06em;

        @include Media (575) {
          @include poppins(2.5, 3);
        }

        .roadMapImg {
          width: 100px;

          @include Media(575) {
            width: 50px;
          }
        }
      }

      .contentSec {
        width: calc(100% - 110px);

        @include Media(575) {
          width: calc(100% - 65px);
        }

        li {
          @include poppins(1.6, 1);
          line-height: 40px;
          font-weight: 500;
          list-style-type: disc;

          @include Media(767) {
            @include poppins(1.2, 1);
            line-height: 30px;
          }

        }
      }
    }

  }

  /*team explore section*/
  .teamExploreSec {
    position: relative;
    padding-bottom: 100px;
    padding-top: 50px;
    background: url('../../public/assets/images/explore/teamBg.jpg') center/cover no-repeat;

    h1 {
      margin-bottom: 20px;
      @include exploreTitle(5, center);

      @include Media(767) {
        @include exploreTitle(3.5, center);
      }

      @include Media(575) {
        @include exploreTitle(2, center);
        margin-bottom: 50px;
      }
    }

    img {
      @include Media(767) {
        margin-bottom: 50px;
      }
    }

    .teamDetails {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .imgSection {
        position: relative;

        .insta {
          position: absolute;
          max-width: 30%;
          bottom: 3%;
          right: 10%;
        }
      }

      .userName {
        @include maxDriveRegular(1.2, 1);

        @include Media(767) {
          font-size: 1rem;
        }
      }

      .designation {
        @include poppins(1.2, 5);
        text-align: center;
      }
    }
  }

  /*contact us explore section */
  .contactUsExploreSec {
    padding-top: 50px;
    padding-bottom: 50px;
    background: url('../../public/assets/images/explore/contactBg.jpg') center/cover no-repeat;

    .footerDiv {
      h1 {
        @include exploreTitle(5, center);

        @include Media(767) {
          @include exploreTitle(3.5, center);
        }

        @include Media(575) {
          @include exploreTitle(2, center);
        }
      }

      .footerLinks {
        padding-top: 4rem;

        li {
          @include poppins(1.5, 1);
          padding: 5px 0;

          @include Media(575) {
            font-size: 1.2rem;
          }
        }
      }

      .FooterContent {
        align-items: flex-start;

        @include Media(480) {
          align-items: center;
        }

        p {
          @include poppins(1.5, 1);
        }

        a {
          display: block;
          background: nth($color, 6);
          border: 2px solid nth($color, 7);
          box-shadow: 0px 0px 18px #4bd9c7, 0px 0px 24px rgba(0, 99, 170, 0.97),
            0px 0px 18px #00f0ff, 0px 0px 6px rgba(0, 240, 255, 0.5),
            0px 0px 8px #5bd5fc, 0px 0px 12px #e80a89;
          @include poppins(1.2, 1);
          padding: 10px 40px;
          margin-top: 35px;
        }
      }

      .socialLinks {
        margin: 50px 0;

        li {
          width: 30%;

          a {
            display: block;
            margin: 10px auto;
            cursor: pointer;

            img {
              width: 56px;
            }
          }
        }
      }

      .cprytDiv {
        align-items: flex-end;
        padding-top: 100px;

        @include Media(480) {
          padding-top: 35px;
          flex-direction: column;
          align-items: center;
        }

        p {
          @include Merriweather(1.2, 1);

          @include Media(480) {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

.scrollbarContent::-webkit-scrollbar {
  display: none;
}

.scrollbarContent:hover::-webkit-scrollbar {
  display: block;
}