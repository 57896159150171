/*Syndicate Section*/
.syndicateSec {
  position: relative;
  padding: 150px 50px;
  background-image: url('../../public/assets/images/nfts/syndicateBg.png');
  background-position: center;
  background-size: cover;

  @include Media(575) {
    padding-bottom: 0;
  }

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 3rem;
      margin-bottom: 50px;
    }
  }

  .col-sm-12 {
    padding: 25px 50px;
  }

  .syndicateRow {
    position: relative;
    .overlayTrigger{
      position: absolute !important;
      bottom: 0;
    }

    .col-sm-12:nth-child(1) {
      .syndicateDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(2) {
      .syndicateDiv {
        border: 2.81648px solid #793F16;
      }
    }

    .col-sm-12:nth-child(3) {
      .syndicateDiv {
        border: 2.81648px solid #810202;
      }
    }

    .col-sm-12:nth-child(4) {
      .syndicateDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(5) {
      .syndicateDiv {
        border: 2.81648px solid #F1B123;
      }
    }

    .col-sm-12:nth-child(6) {
      .syndicateDiv {
        border: 2.81648px solid #810202;
      }
    }

    .col-sm-12:nth-child(7) {
      .syndicateDiv {
        border: 2.81648px solid #8FFFED;
      }
    }

    .col-sm-12:nth-child(3) {
      img {
        top: -50px;
        right: -50px;
        height: 120px;

        @include Media(991) {
          right: -31px;
          height: 130px;
        }
      }
    }

    .col-sm-12:nth-child(7) {
      img {
        top: -50px;
        right: -50px;

        @include Media(991) {
          right: -31px;
          height: 130px;
        }
      }
    }

    .syndicateDiv {
      position: relative;
      border: 1px solid red;
      padding: 15px;
      justify-content: center;
      align-items: flex-end;
      min-height: 180px;
      margin-bottom: 100px;

      &:hover {
        img {
          @include TransForm(scale(1.1));
        }
      }

      @include Media(575) {
        min-height: 150px;
      }

      p {
        text-align: center;
        text-transform: uppercase;
        @include maxDriveRegular(1.2, 1);

        @include Media(575) {
          font-size: 1rem;
        }
      }

      img {
        position: absolute;
        top: -80px;
        right: -60px;
        width: auto;
        max-height: 200px;
        @include Tran(.4s, ease-in-out);

        @include Media (575) {
          top: -60px;
          right: -31px;
          height: 150px;
        }
      }
    }


  }
}

/*Special Section*/
.specialNftSec {
  position: relative;
  padding: 100px 0;
  background-image: url('../../public/assets/images/nfts/specialNftBg.png');
  background-position: center;
  background-size: cover;

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 2.6rem;
      margin-bottom: 30px;
    }
  }

  .specialNftDiv {
    position: relative;
    margin-bottom: 30px;

    p {
      text-align: center;
      text-transform: uppercase;
      @include maxDriveRegular(2, 1);

      @include Media(991) {
        font-size: 1.5rem;
      }

      @include Media(576) {
        font-size: 1.3rem;
      }
    }

    .imgDiv {
      position: relative;
      padding: 20px;
      margin-bottom: 30px;
      border-left: 2px solid #a4ff6b;
      border-bottom: 2px solid #a4ff6b;

      &.secondDIv {
        border-color: #432d8c;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

/*land Nfts*/

.landNftsSec {
  background: url('../../public/assets/images/nfts/landBg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 100px 0;

  @include Media(575) {
    padding-bottom: 0;
  }

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 3rem;
      margin-bottom: 50px;
    }
  }

  .col-sm-12 {
    padding: 25px 50px;
  }

  .landRow {
    position: relative;

    .col-sm-12:nth-child(1) {
      .landDiv {
        border: 2.81648px solid #FF0303;
      }
    }

    .col-sm-12:nth-child(2) {
      .landDiv {
        border: 2.81648px solid #793F16;
      }
    }

    .col-sm-12:nth-child(3) {
      .landDiv {
        border: 2.81648px solid #810202;
      }
    }

    .col-sm-12:nth-child(4) {
      .landDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(5) {
      .landDiv {
        border: 2.81648px solid #247000;
      }
    }

    .col-sm-12:nth-child(5) {
      img {
        right: -100px;
      }
    }

    .landDiv {
      position: relative;
      border: 1px solid red;
      padding: 15px;
      justify-content: center;
      align-items: flex-end;
      min-height: 180px;
      margin-bottom: 100px;

      &:hover {
        img {
          @include TransForm(scale(1.1));
        }
      }

      @include Media(575) {
        min-height: 150px;
      }

      p {
        text-align: center;
        text-transform: uppercase;
        @include maxDriveRegular(1.2, 1);

        @include Media(575) {
          font-size: 1rem;
        }
      }

      img {
        position: absolute;
        top: -80px;
        right: -60px;
        width: auto;
        max-height: 200px;
        @include Tran(.4s, ease-in-out);

        @include Media (575) {
          top: -60px;
          right: -31px;
          height: 150px;
        }
      }
    }


  }
}

/*distribution Nfts*/
.distributionNftsSec {
  background: url('../../public/assets/images/nfts/distributionBg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 100px 0;

  @include Media(575) {
    padding-bottom: 0;
  }

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 2.2rem;
      margin-bottom: 80px;
    }
  }

  .col-sm-12 {
    padding: 25px 60px;
  }

  .distributionRow {
    position: relative;

    .col-sm-12:nth-child(1) {
      .distributionDiv {
        border: 2.81648px solid #FF0303;
      }
    }

    .col-sm-12:nth-child(2) {
      .distributionDiv {
        border: 2.81648px solid #793F16;
      }
    }

    .col-sm-12:nth-child(3) {
      .distributionDiv {
        border: 2.81648px solid #810202;
      }
    }

    .col-sm-12:nth-child(4) {
      .distributionDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(5) {
      .distributionDiv {
        border: 2.81648px solid #247000;
      }
    }

    .col-sm-12:nth-child(1) {
      img {
        top: -110px;

        @include Media (991) {
          top: -60px;
        }
      }
    }

    .distributionDiv {
      position: relative;
      border: 1px solid red;
      padding: 15px;
      justify-content: center;
      align-items: flex-end;
      min-height: 180px;
      margin-bottom: 100px;
      color: white;

      &:hover {
        img {
          @include TransForm(scale(1.1));
        }
      }

      @include Media(575) {
        min-height: 150px;
      }

      p {
        text-align: center;
        text-transform: uppercase;
        padding-right: 5px;
        @include maxDriveRegular(1.2, 1);

        @include Media(575) {
          font-size: 1rem;
        }
      }

      img {
        position: absolute;
        top: -70px;
        right: -90px;
        width: auto;
        max-height: 200px;
        @include Tran(.4s, ease-in-out);

        @include Media (991) {
          top: -25px;
          right: -60px;
        }

        @include Media (575) {
          top: -60px;
          right: -31px;
          height: 150px;
        }
      }
    }


  }
}

/*laundering Nfts*/
.launderingNftsSec {
  background: url('../../public/assets/images/nfts/launderingBg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 100px 0;

  @include Media(575) {
    padding-bottom: 0;
  }

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 2.5rem;
      margin-bottom: 80px;
    }
  }

  .col-sm-12 {
    padding: 25px 130px;

    @include Media(991) {
      padding: 25px 60px;
    }
  }

  .launderingRow {
    position: relative;

    .col-sm-12:nth-child(1) {
      .launderingDiv {
        border: 2.81648px solid #52FF00;
      }
    }

    .col-sm-12:nth-child(2) {
      .launderingDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(3) {
      .launderingDiv {
        border: 2.81648px solid #AE5EFF;
      }
    }

    .col-sm-12:nth-child(1) {
      img {
        top: -110px;
      }
    }

    .launderingDiv {
      position: relative;
      border: 1px solid red;
      padding: 15px;
      justify-content: center;
      align-items: flex-end;
      min-height: 180px;
      margin-bottom: 100px;
      color: white;

      &:hover {
        img {
          @include TransForm(scale(1.1));
        }
      }

      @include Media(575) {
        min-height: 150px;
      }

      p {
        text-align: center;
        text-transform: uppercase;
        padding-right: 5px;
        @include maxDriveRegular(1.2, 1);

        @include Media(575) {
          font-size: 1rem;
        }
      }

      img {
        position: absolute;
        top: -70px;
        right: -90px;
        width: auto;
        max-height: 200px;
        @include Tran(.4s, ease-in-out);

        @include Media (575) {
          top: -60px;
          right: -31px;
          height: 150px;
        }
      }
    }
  }
}

/*transport Nfts*/
.transportNftsSec {
  background: url('../../public/assets/images/nfts/transportBg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 100px 0;

  @include Media(575) {
    padding-bottom: 0;
  }

  h2 {
    @include sectionTitle(5, center);
    text-align: center;
    margin-bottom: 100px;

    @include Media(575) {
      font-size: 2.5rem;
      margin-bottom: 50px;
    }
  }

  .col-sm-12 {
    padding: 25px 60px;
  }

  .transportRow {
    position: relative;

    .col-sm-12:nth-child(1) {
      @include Media(767) {
        margin-top: 0;
      }

      .transportDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(2) {
      .transportDiv {
        border: 2.81648px solid #9AFF6A;
      }
    }

    .col-sm-12:nth-child(3) {

      .transportDiv {
        border: 2.81648px solid #4BD9C7;
      }
    }

    .col-sm-12:nth-child(4) {
      .transportDiv {
        border: 2.81648px solid #810202;
      }
    }

    .transportDiv {
      position: relative;
      border: 1px solid red;
      padding: 15px;
      justify-content: center;
      align-items: flex-end;
      min-height: 180px;
      margin-bottom: 100px;
      color: white;

      &:hover {
        img {
          @include TransForm(scale(1.1));
        }
      }

      @include Media(575) {
        min-height: 150px;
      }

      p {
        text-align: center;
        text-transform: uppercase;
        padding-right: 5px;
        @include maxDriveRegular(1.2, 1);

        @include Media(575) {
          font-size: 1rem;
        }
      }

      img {
        position: absolute;
        top: -60px;
        right: -90px;
        width: auto;
        max-height: 200px;
        @include Tran(.4s, ease-in-out);

        &.mule {
          top: -80px;
          right: -65px;
        }

        @include Media (991) {
          top: -40px;
          right: -60px;

          &.mule {
            right: -65px;
          }
        }

        @include Media (575) {
          top: -60px;
          right: -31px;
          height: 150px;

          &.mule {
            right: -65px;
          }
        }
      }
    }
  }
}