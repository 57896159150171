.aboutSec {
  background: url('../../public/assets/images/about/aboutBg.png');
  background-size: cover;
  background-position: center;
  padding: 150px 0px;

  h1 {
    margin-bottom: 50px;
    @include sectionTitle(5, center);

    @include Media(767) {
      font-size: 4rem;
    }

    @include Media(480) {
      font-size: 3rem;
    }
  }

  .contentWrapper {
    @include Media(767) {
      text-align: center;
    }

    &.middleWrapper {
      @include Media(767) {
        flex-wrap: wrap-reverse;
      }
    }

    img {
      @include Tran(1s, ease-in-out);

      &.w-60 {
        width: 60%;
      }

      @include Media(767) {
        max-width: 55%;
        margin-bottom: 20px;
      }

      &:hover {
        @include TransForm(rotateY(30deg));
      }
    }

    .aboutContent {
      @include gilroyRegular(1.5, 1);

      @include Media(991) {
        padding: 10px 5px;
        font-size: 1.2rem;
        margin-bottom: 50px;
      }

      @include Media(575) {
        text-align: center;

        font-size: 1rem;
      }



      .colorText {
        color: nth($color, 3);
      }

      .skyColor {
        color: nth($color, 7);
      }
    }
  }
}