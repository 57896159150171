@import "~bootstrap/scss/bootstrap";
@import 'variables';
@import 'html';
@import 'header';
@import 'home';
@import 'whitePaper';
@import 'about';
@import 'tokens';
@import 'roadmap';
@import 'team';
@import 'nfts';
@import 'footer';
@import 'faq';
@import 'explore';
@import 'privacyPolicy';

.accordion-button {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid #fff;
}

.accordion-button::after {
  background-image: url('./arrow.png') !important;
}

.accordion-button:focus {
  border-color: #e7f1ff;
  box-shadow: none
}

.tooltip {
  padding: 5px;
}