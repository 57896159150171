.tokensSec {
  position: relative;
  padding: 150px 20px 30px;

  .tokensDiv {
    h1 {
      @include sectionTitle(5, right);
      padding-bottom: 50px;

      @include Media(767) {
        font-size: 4rem;
        text-align: center;
      }

      @include Media(480) {
        font-size: 3rem;
      }
    }

    .leftTokenContent {
      position: relative;

      img {
        margin: 30px auto;
        width: 70%;

        @include Media(991) {
          width: 50%;
          margin: 10px auto;
        }
      }

      .rightVectors {
        position: relative;

        p {
          @include maxDriveRegular(1.1, 1);
          line-height: 21px;
          letter-spacing: 0.06em;
          position: absolute;
          top: 40%;

          &.t28 {
            top: 30%;
          }

          @include Media(991) {
            font-size: 1.8rem;
          }

          @include Media(480) {
            font-size: 1rem;
          }
        }

        &.vectorOneSec {
          p {
            left: 12%;
            top: 45%;
          }

          @include Media(480) {
            top: 30%;
          }
        }

        &.vectorTwoSec {
          text-align: right;

          @include Media(767) {
            text-align: left;
          }

          p {
            right: -7%;
            top: 45%;

            @include Media(480) {
              left: 8%;
              top: 30%;
            }

            @include Media(767) {
              left: 25%;
            }
          }
        }
      }
    }

    .tokensBtns {
      position: relative;

      a {
        margin-bottom: 10px;
        position: relative;
        padding: 10px 30px;
        background: nth($color, 3);
        border: 1px solid nth($color, 3);
        @include maxDriveRegular(1, 1);
        clip-path: polygon(93% 0, 100% 36%, 100% 100%, 0 100%, 0 0);
        @include BorRadius(5px);
        @include Tran(0.4s, ease-in-out);

        &:hover {
          color: nth($color, 3);
          background: transparent;
        }

        @include Media(575) {
          padding: 5px 25px;
        }
      }

      a:nth-child(2) {
        padding: 10px 30px;
        background: nth($color, 2);
        border-color: #a23001;

        &:hover {
          background: transparent;
          color: #a23001;
        }

        @include Media(575) {
          padding: 5px 25px;
          margin: 10px 0;
        }
      }
    }

    .leftTokenSection {
      position: relative;

      .title {
        @include sectionTitle(3.5, left);
        position: absolute;
        top: 47%;
        left: 48%;

        @include Media(991) {
          font-size: 3rem;
        }

        @include Media(767) {
          font-size: 2.5rem;
        }

        @include Media(480) {
          font-size: 2rem;
        }
      }

      p {
        @include maxDriveRegular(1.1, 1);
        text-align: center;
        max-width: 20%;
        line-height: 21px;

        @include Media(991) {
          font-size: 0.8rem;
        }

        &.first {
          position: absolute;
          top: 16%;
          left: 25%;
        }

        &.second {
          position: absolute;
          top: 44%;
          left: 13%;
        }

        &.third {
          position: absolute;
          bottom: 20%;
          left: 20%;
        }
      }
    }

    .rightTokenSection {
      position: relative;

      .rightVectors {
        position: relative;

        p {
          @include maxDriveRegular(1.1, 1);
          letter-spacing: 0.06em;
          position: absolute;
          top: 45%;

          &.t28 {
            top: 30%;
          }

          @include Media(991) {
            font-size: 1.8rem;
          }

          @include Media(480) {
            font-size: 1rem;
          }
        }

        img {
          width: 40%;
        }

        &.vectorOneSec {
          p {
            left: 15%;
          }
        }

        &.vectorTwoSec {
          text-align: right;

          @include Media(767) {
            text-align: left;
          }

          img {
            width: 40%;
          }

          p {
            right: -10%;

            @include Media(480) {
              left: 8%;
            }

            @include Media(767) {
              left: 25%;
            }
          }
        }

        &.vectorThreeSec {
          p {
            left: 20%;
          }
        }
      }
    }
  }
}