.footerSec {
  position: relative;
  min-height: 100vh;
  padding: 150px 0px 30px;

  .footerDiv {
    h1 {
      @include sectionTitle(6, right);

      @include Media(767) {
        font-size: 4rem;
        text-align: center;
      }

      @include Media(575) {
        font-size: 2.5rem;
      }
    }

    .footerLinks {
      padding-top: 4rem;

      li {
        @include Merriweather(2, 1);
        padding: 5px 0;

        @include Media(575) {
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }

    .FooterContent {
      align-items: flex-end;
      padding-top: 3rem;

      @include Media(767) {
        align-items: flex-start;
      }

      @include Media(480) {
        align-items: center;
      }

      p {
        @include Merriweather(2.5, 1);

        @include Media(575) {
          font-size: 2rem;
        }
      }

      a {
        display: block;
        background: nth($color, 6);
        border: 2px solid nth($color, 7);
        box-shadow: 0px 0px 18px #4bd9c7, 0px 0px 24px rgba(0, 99, 170, 0.97),
          0px 0px 18px #00f0ff, 0px 0px 6px rgba(0, 240, 255, 0.5),
          0px 0px 8px #5bd5fc, 0px 0px 12px #e80a89;
        @include Merriweather(1.2, 1);
        padding: 10px 40px;
        font-weight: bold;
        margin-top: 35px;
      }
    }

    .socialLinks {
      margin-top: 50px;

      li {
        width: 30%;

        a {
          display: block;
          margin: 10px auto;
          cursor: pointer;

          img {
            width: 56px;
          }
        }
      }
    }

    .cprytDiv {
      align-items: flex-end;
      padding-top: 200px;

      @include Media(480) {
        padding-top: 50px;
        flex-direction: column;
        align-items: center;
      }

      p {
        @include Merriweather(1.2, 1);

        @include Media(480) {
          padding-bottom: 20px;
        }
      }
    }
  }
}